<template>
    <div>
        <div class="box has-text-centered">
            Finding your policy...
        </div>

        <!-- Message modal -->
        <b-modal v-model="errorModal" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <p>Dear Customer,</p>
                    <br>
                    <p>We hope you are keeping safe and well.</p>
                    <br>
                    <p>During these unprecedented times it has been essential for us to ensure our products continue to be fit for purpose.</p>
                    <br>
                    <p>Due to improving our products, which now include enhanced, Covid-19 Cover, we are unable to honour the renewal quote previously provided, as we can no longer provide your cover on a ‘like-for-like’ basis. </p>
                    <br>
                    <p>However, we would be absolutely delighted for you to obtain a <a href="https://www.covered2go.co.uk/get-quote">NEW QUOTE</a> and would like to offer you the same <strong>5% DISCOUNT</strong> for choosing to stay with us by using the code <strong>RENEWAL5</strong> at the checkout. To view our products, and obtain a new quotation, please <a href="https://www.covered2go.co.uk/get-quote">click here</a>.</p>
                    <br>
                    <p>If we can be of any further assistance, please do not hesitate to contact us.</p>
                    <br>
                    <p>Best wishes,</p>
                    <p>Your Covered2go Team.</p>
                </div>
            </div>
        </b-modal>

        <!-- Message modal -->
        <b-modal v-model="expiredModal" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <p>Dear Customer,</p>
                </div>
            </div>
        </b-modal>
    </div>
    

</template>

<script>
    import { policyAPI } from "@/mixins/policy-api";
    import { maintenance } from "@/mixins/maintenance";
    import { mapActions } from 'vuex';

    export default {
        name: "PolicyRedirect",
        props: ['id'],
        data() {
            return {
                errorModal: false,
                expiredModal: false
            }
        },
        mixins: [policyAPI,maintenance],
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
                setReqPolicy: 'setReqPolicy',
                setUpsellPolicy: 'setUpsellPolicy'
            })
        },
        watch: {
            errorModal(enabled) {
                if(!enabled) {
                    this.$router.push({ name:'home' });
                }
            }
        },
        mounted() {
            const product = this.$route.query.product;
            const pid = isNaN(this.id) ? atob(this.id) : this.id;
            this.retrievePolicy(pid).then((res) => {
                const policy = res.data;

                // Set the object
                this.setPolicy(policy);
                this.setReqPolicy(policy);
                this.setUpsellPolicy(null);

                // No covid cover on retieved policy
                if(policy.policy.covid19 === 'N' && policy.policy.policy_product !== 'silver') {
                    this.errorModal = true;
                    // this.$router.push({ name:'home' });
                
                // Quote
                // If start date is after today
                // If has covid cover
                // If current date is before 14days from the policy being issued
                } else if(
                    policy.policy.status === 'quote' 
                    && this.$moment(policy.policy.start_date).isSameOrAfter(this.$moment(new Date().toISOString().split('T')[0])) 
                    && this.$moment(this.$moment(new Date().toISOString().split('T')[0])).isSameOrBefore(this.$moment(policy.policy.issue_date).add(14, 'days')) 
                ) {
                    // If product is passed
                    if(product) {
                        this.selectProduct(pid, product).then((res) => {
                            if(res.data.quote) {
                                this.setPolicy(res.data);
                                this.$router.push({name: 'yourDetails'});
                            }
                        });

                    } else {
                        this.$router.push({ name:'policyDetails' });
                    }
                    
                // Renewal
                } else if(policy.policy.status === 'renewal') {
                    // If start date is before today
                    if(new Date(policy.policy.start_date) < new Date()) {
                        const startDate = this.$moment(new Date()).format('YYYY-MM-DD');
                        const endDate = this.$moment(new Date()).add('1', 'year').format('YYYY-MM-DD');
                        policy.policy.start_date = startDate;
                        policy.policy.end_date = endDate;
                        policy.quote.start_date = startDate;
                        policy.quote.end_date = endDate;

                        this.savePolicy(policy).then(() => {
                            this.setPolicy(policy);
                            this.$router.push({ name:'purchasePolicy' });
                        });
                    } else {
                        this.$router.push({ name:'purchasePolicy' });
                    }

                // Policy
                } else if(policy.policy.status === 'policy') {
                    this.$router.push({ name:'confirmation' });

                // Default home
                } else {
                    this.$router.push({ name:'home' });
                }
            });
        },
        async created() {
            await this.maintenanceCheck();
        }
    }
</script>

<style scoped>

</style>``